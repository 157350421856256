* {
  box-sizing: border-box;
}

:root {
  --dark-gray: rgb(151, 150, 150);
  --light-gray-full: rgb(243, 243, 243);
  --light-gray: rgba(243, 243, 243, 0.637);
  --hover-gray: rgba(231, 229, 229, 0.39);
  --gray-border: rgba(173, 172, 172, 0.664);
  --gray-border-light: rgba(173, 172, 172, 0.4);
  --shadow: 1px 1px 6px rgba(0,0,0,.15);
  --shadow-disabled: 2px 2px 15px rgba(0,0,0,.04);
  --new-element: rgb(55, 181, 55);
}

.dx-scrollable-content {
  overflow: hidden !important;
}

.historial-floating-btn {
  position: fixed;
  font-size: 0.9em;
  padding: 0.5em 1em;
  background-color: var(--secondary-color);
  bottom: 7px;
  right: 40px;
  border-radius: 30px;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

.floating-button{
	position:fixed !important;
	/* width:60px; */
	height:60px;
	bottom:40px;
	right: 40px;
	text-align:center;
}

.floating-button-viewer{
	position:fixed !important;
	/* width:60px; */
	height:30px;
	top: 105px;
	left: 80px;
	text-align:center;
}

.floating-button > .dx-button-content > .dx-icon{
  color: #fff !important
}

.floating-button-viewer > .dx-button-content > .dx-icon{
  color: #fff !important
}

.ficha-container {
    display: flex;
    flex-grow: 1;
    /* height: calc(100vh - 350px); */
    justify-content: space-between;
    background-color: var(--light-gray);
    border-top: 1px solid var(--gray-border);
    box-shadow: 0px -8px 15px 0px rgba(0,0,0,0.03);
    -webkit-box-shadow: 0px -8px 15px 0px rgba(0,0,0,0.03);
    -moz-box-shadow: 0px -8px 15px 0px rgba(0,0,0,0.03);
    /* overflow: hidden; */
}

.form-container {
  /* padding: 10px; */
  transition: height 400ms ease-in-out;
}

.form-container:hover {
  /* box-shadow: var(--shadow) inset,
  var(--shadow-disabled); */
}


.form-container.focused .text-input-title{
  color: var(--main-color)
}

.form-container-white {
  padding: 10px 20px;
  border-radius: 7px;
  transition: height 400ms ease-in-out;
  background-color: #fff;
}

.ficha-container ul {
  margin: 0;
  padding: 0;
}

.form-content {
  width: 100%;
  /* overflow: hidden; */
  list-style-type: none;
}

/* Sections */
.section-list-container {
  overflow-y: hidden;
  overflow-x: visible;
  height: 100%;
  width: 200px;
  border-right: 1px solid var(--gray-border);
  direction: rtl;
  transition: width 300ms ease-in-out;
  position: relative;
}

.section-list-container.section-list-closed {
  width: 50px;
}

.section-list-container ul{
  height: 100%;
  width: 100%;
  height: calc(100% - 130px);
  overflow-y: auto;
  display: inline-block;
}

.section-list-container.section-list-closed ul{
  /* display: none; */
}

.section-list-toggler {
  padding: 0px 10px ;
  position: absolute;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #fff;
  bottom: 0px;
  left: 0px;
}

.section-box {
  position: relative;
  height: 80px;
  border-bottom: 1px solid var(--gray-border);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
  transition: background-color 200ms ease-in-out;
  text-align: center;
  direction: ltr;
  overflow: auto !important;
}

.section-box p{
  font-weight: 700;
  font-size: 1.2em;
  color: var(--dark-gray);
  transition: color 200ms ease-in-out;
  padding: 0px 15px;
}

.section-box:hover, .section-box.active {
  background-color: #fff;
}

.section-box:hover p {
  color: var(--main-color);
}

.section-box:hover::after, .section-box.active::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 5px;
  background-color: var(--main-color);
  right: 0;
}

.section-box.active p {
  color: var(--main-color);
}

.section-box:hover::before {
  /* position: absolute;
  content: "";
  height: 100%;
  width: 25px;
  right: 5px;
  cursor: grab; */
}


/* Management */
.management-list-container {
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  background-color: var(--light-gray);
}

.management-box {
  position: relative;
  height: 80px;
  min-width: 220px;
  border-top: 2px solid var(--gray-border-light);
  border-left: 1px solid var(--gray-border-light);
  border-right: 1px solid var(--gray-border-light);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
  transition: background-color 200ms ease-in-out;
  text-align: center;
  direction: ltr;
}

.management-box.active, .management-box:hover {
  border-top: 0px solid var(--gray-border-light);
}

.management-box:first-child {
  border-left: 2px solid var(--gray-border-light);
}

.management-box:last-child {
  border-right: 2px solid var(--gray-border-light) !important;
}

.management-box p{
  font-weight: 700;
  font-size: 1.2em;
  color: var(--dark-gray);
  transition: color 200ms ease-in-out;
  padding: 0px 15px;
}

.management-box:hover, .management-box.active {
  background-color: #fff;
}

.management-box:hover p {
  color: var(--main-color);
}

.management-box:hover::after, .management-box.active::after {
  position: absolute;
  content: "";
  height: 5px;
  width: 100%;
  background-color: var(--main-color);
  right: 0;
  top: 0;
}

.management-box.active p {
  color: var(--main-color);
}

.management-box:hover::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 25px;
  right: 5px;
  cursor: grab;
}

.unique-add-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
  padding: 20px 0px;
}

.past-current-btn-group .dx-button {
  margin-right: 20px;
}

.past-current-title {
  flex-grow: 1;
  color: var(--main-color);
  font-weight: 700;
  font-size: 1.5em;
  margin: 0 30px;
}
/* Questions */
.questions-container {
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% - 220px);
  padding: 10px;
  flex-grow: 1;
}

.section-layout {
  width: 100%;
  /* padding: 20px 0; */
  border-bottom: 1px solid var(--gray-border-light);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.02);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.02);
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.02);
  transition: all 300ms ease-in-out;
}

.section-layout.active {
  background-color: #fff;
}

.section-title {
  padding-bottom: 20px;
  font-size: 1.6em;
  font-weight: 500;
  color: var(--dark-gray);
  margin: 0;
}

.section-title.active, .section-title-small.active {
  color: var(--main-color);
}

.section-title-small {
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100%;
  top: 0px;
  background-color: var(--light-gray-full);
  font-size: 1.6em;
  font-weight: 500;
  color: var(--main-color);
  margin: 0;
  padding: 20px 0;
  z-index: 999;
}

.section-title-small:after {
  content: "";
  position: absolute;
  background-color: var(--main-color);
  bottom: 0;
  width: 100%;
  height: 3px;
}
.section-content {
  display: flex;
  flex-wrap: wrap;
}

.number-input-indigo:focus {
  border: 1px solid var(--main-color) !important;
  outline: none !important;
}

.number-box-indigo {
  border: 0px !important;
}


.text-input-container {
  position: relative;
  justify-self: flex-start !important;
  /* margin-top: -10vh; */
  padding: 15px 2px;
  overflow: visible;
}

.flip {
  transform: rotate(180deg);
}

.panel-toggler {
  display: flex;
  justify-content: center;
}

.lookup-text-input {
  font-size: 0.8em !important;
  padding: 0px 0px !important;
  justify-self: center;
  border: 1px solid transparent;
  color: var(--dark-gray) !important;
  border-radius: 7px;
  background-color: #fff !important;
  box-shadow: var(--shadow) inset;
  height: 39px !important;
}

.lookup-text-input .dx-lookup-field {
  font-size: 0.8em !important;
}

.lookup-text-input .dx-lookup-field {
  padding: 10px 15px !important;
}

.lookup-text-input .dx-lookup-arrow {
  display: none !important;
}

.text-input {
  width: 100%;
}
.text-input-container.grabbed {
  border: 2px solid var(--main-color);
  border-radius: 10px;
  background-color: var(--main-transparent);
}

.text-input-title {
  display: flex;
  justify-content: space-between;
}

.text-input-title li, 
.inputComment-label li {
  cursor: pointer;
}

.dx-tab.dx-tab-selected,
.text-input-container.focused 
.text-input-title, 
.text-input-container.grabbed 
.text-input-title, 
.text-input-container.focused label {
  color: var(--main-color) !important;
}

.text-input-container.focused 
.text-input {
  border: 1px solid var(--main-color);
  color: var(--main-color);
}

.text-input-container .unit {
  position: absolute;
  right: 17px;
  bottom: 26px;
  color: var(--dark-gray);
  user-select: none;
  opacity: 0.6;
}


.text-input-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  padding-bottom: 10px;
  font-size: 1.2em;
  font-weight: 500;
  color: var(--dark-gray);
  margin: 0;
}

.text-input-title label {
  width: 90%;
}

.text-input-title .dx-link, .inputComment-label .dx-link {
  background-color: transparent !important;
}

.text-input-title .dx-link, .inputComment-label .dx-link {
  color: var(--gray-border-light) !important;
}

.text-input-title .dx-link:hover, .text-input-title.contextOpen .dx-link, .inputComment-label .dx-link:hover{
  color: var(--main-color) !important;
}

.text-input-title .dx-button-has-icon .dx-link, .inputComment-label .dx-button-has-icon .dx-link{
  width: 18px !important;
  height: 18px !important;
  background-size: 18px 18px !important;
  font-size:  18px !important;
  line-height: 18px !important;
}

.text-input, 
.text-input-container 
.dx-texteditor-input, 
.indigo-input .dx-texteditor-input, 
.mgmt-drop-input {
  justify-self: center;
  /* border: 1px solid var(--gray-border); */
  border: 1px solid transparent;
  color: var(--dark-gray);
  padding: 10px 15px !important;
  border-radius: 7px;
  background-color: #fff !important;
  box-shadow: var(--shadow) inset;
  height: 39px;
  font-size: 12px;
}

.dx-placeholder {
  font-size: 12px !important;
}

.text-input.disabled {
  box-shadow: var(--shadow-disabled) inset;
  border: 1px solid transparent !important;
}

.dx-button.dx-button-normal.dx-button-mode-text.dx-widget.dx-button-has-icon {
  margin-left: 5px;
}

.dx-button.dx-button-normal.dx-button-mode-text.dx-widget.dx-button-has-icon.dx-state-focused,
.input-eliminator:focus {
  background-color: var(--main-transparent) !important;
}

.dx-datagrid-borders > .dx-datagrid-headers,
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border: 1px solid transparent !important;
}

.text-input::-webkit-outer-spin-button,
.text-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-input[type=number] {
  -moz-appearance: textfield;
}

input {
  font-size: 0.8em;
}

.text-input:focus, 
.text-input:active, 
.mgmt-drop-input:focus, 
.mgmt-drop-input:active,
.text-input-container.focused .dx-texteditor-input,
.dx-texteditor.dx-state-focused .dx-texteditor-input
{
  border: 1px solid var(--main-color) !important;
  outline: none !important;
}

.dx-texteditor.dx-editor-outlined.dx-state-focused {
  box-shadow: none !important;
}

.text-input input:hover + .text-input-title,
.dx-dateview-item-selected {
  color: var(--main-color) !important;
}

.dx-placeholder {
  padding-left: 17px;
}
/* Search */

.dx-item-content.dx-list-item-content {
  margin-top: 2px;
  padding: 8px 15px;
  cursor: pointer;
  overflow: hidden;
  font-size: 0.8em;
  color: var(--dark-gray) !important;
}

.dx-item-content.dx-list-item-content:hover {
  background-color: var(--main-transparent);
  color: var(--main-color);
}

.search-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.search-toast {
  user-select: none;
  opacity: 0;
  /* width: 0; */
  height: 0;
  text-align: right;
  font-size: 0.7em;
  color: var(--new-element);
  transition: all 300ms ease-in-out;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.search-toast.active {
  opacity: 1;
  /* width: 40%; */
  height: 100%;
}

.search-tags-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 10px;
  /* padding: 10px; */
  margin-top: 10px;
  transition: all 300ms ease-in-out;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.search-tags-container.read-only {
  padding: 0px;
  margin-top: 0px;
}

.search-tags-container-small:hover{
  background-color: var(--light-gray);
}

.search-tags-container-small {
  position: relative;
  margin-top: 10px;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  transition: all 300ms ease-in-out;
  max-height: 180px;
  overflow-y: auto;
  overflow-x: hidden;
}


.search-tags-container-small::after {
  top: 0;
  left: 0;
}

.search-tags-container-small::before {
  bottom: 0;
  left: 0;
}

.search-input-container {
  position: relative;
  min-width: 30%;
}

.search-input-icon {
  position: absolute;
  top: 2px;
  right: 0;
}

.search-tag-comment {
  display: flex;
  justify-content: space-between;
  max-width: 50%;
}

.search-tag-comment.has-comment {
  width: 100%;
  max-width: 100%;
}

.search-tag-comment.preloaded {
  width: 49%;
  max-width: 50%;
}

.search-tags-container.preloaded {
  flex-direction: column;
  justify-content: space-between;
}

.large {
  flex-grow: 1;
}
.commentor {
  position: relative;
}

.search-input-container.commentor {
  margin: 2px 2px;
  flex-grow: 0;
}

.search-input-container.commentor:first-child {
  margin-top: 0;
}

.commentor span {
  position: absolute;
  right: 8px;
  top: 10px;
  color: var(--dark-gray);
  user-select: none;
  opacity: 0.6;
}

.no-focus {
  border: 1px solid transparent !important;
  color: var(--dark-gray) !important; 
}

.search-comment-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
  flex-grow: 1;
  min-width: 50%;
}

.search-comment-small {
  position: absolute;
  top: 45px;
  left: 5%;
  width: 90%;
  height: 0px;
}

.search-comment-small.selected {
  height: 39px;
}

.search-tag {
  margin: 3px 4px;
  padding: 7px 12px 7px 12px;
  color: var(--dark-gray);
  border-radius: 30px;
  /* border: 1px solid var(--gray-border); */
  border: 1px solid transparent;
  box-shadow: var(--shadow);
  transition: all 200ms ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 39px;
  width: 100%;
  overflow: hidden;
}

.search-tag.small {
  width: 100%;
  border-radius: 0px;
  overflow: hidden;
}

.search-tag.selected {
  align-items: flex-start;
  height: 100px;
}

.search-tag span {
  margin: 0;
  width: 100%;
  height: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0;
  white-space: nowrap; 
  display: flex;
  align-items: center;
  font-size: 0.9em;
}

.search-tag i{
  width: 10px;
  height: 10px;
  font-size: 10px !important;
  background-size: 10px 10px;
  color: var(--dark-gray) !important; 
}

.search-small-icons-container {
  display: flex;
  align-items: center;
}
.search-tag .dx-icon-contains{
  width: 15px;
  height: 15px;
  margin-right: 15px;
  font-size: 15px !important;
  background-size: 15px 15px;
  color: var(--dark-gray) !important; 
}

.search-tag.active .dx-icon-contains, .search-tag:hover .dx-icon-contains{
  color: var(--main-color) !important; 
}
.search-tag .dx-button-content {
  padding: 0 !important;
}
.search-tag .dx-button {
  height: 24px !important;
  min-width: 24px !important;
}

.two-btns {
  font-weight: 700;
  cursor: pointer;
  color: var(--dark-gray);
  padding: 1.2em 2.5em;
  box-shadow: var(--shadow);
  transition: all 200ms ease-in-out;
}

.two-btns.inactive:hover,
.dx-fa-button.dx-fa-button-main .dx-overlay-content {
  background-color: var(--main-color) !important;
  color: #fff;
}

.dx-fa-button.dx-fa-button-main .dx-overlay-content .dx-icon {
  color: #fff !important;
}

.two-btns:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.two-btns:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.two-btns.active {
  color: var(--main-color);
  box-shadow: var(--shadow) inset;
  cursor: default;
}

.search-tag:hover, .search-tag.active {
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

.search-input-container .text-input{
  z-index: 100;
}
.search-dropdown{
  transition: all 200ms ease-in-out;
  position: absolute;
  top: 40px;
  border-radius: 7px;
  /* padding: 0px 10px; */
  width: 100%;
  background-color: #fff;
  z-index: 50;
  border: 1px solid var(--gray-border);
  overflow: hidden;
  max-height: 140px;
  scroll-behavior: smooth;
}

.search-dropdown-list-container {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: 140px;
  scroll-behavior: smooth;
}

.input-eliminator {
  position: absolute;
  overflow: hidden;
  right: 30px;
  top: 4px;
  height: 29px;
  width: 29px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  outline: none;
  border: none;
}


.input-eliminator:hover,
.input-eliminator::selection,
.input-eliminator:active {
  background-color: var(--light-gray) !important;
}

.input-eliminator:hover .input-eliminator-btn {
  background-color: var(--hover-gray);
}

.input-eliminator-btn {
  width: 100%;
  height: 100%;
  position: relative;
}

.input-eliminator-btn::after {
  width: 1px;
  height: 40%;
  position: absolute;
  left: 47%;
  top: 30%;
  content: "";
  transform: rotate(45deg);
  background-color: var(--dark-gray);
}
.input-eliminator-btn::before {
  width: 1px;
  height: 40%;
  position: absolute;
  left: 47%;
  top: 30%;
  content: "";
  transform: rotate(-45deg);
  background-color: var(--dark-gray);
}

.no-selectable {
  cursor: default !important;
}

.dx-loadindicator-segment-inner {
  border-color: var(--main-color) var(--main-color) transparent !important;
}

.no-selectable:hover {
  background-color: transparent !important;
  color: var(--dark-gray) !important;
}

.main-field-container.hc .search-dropdown{
  max-height: 150px;
}

.search-dropdown-item {
  color: var(--dark-gray);
  margin-top: 2px;
  padding: 8px 15px;
  cursor: pointer;
  max-height: 45;
  overflow: hidden;
}

.search-dropdown-item p {
  font-size: 0.8em;
  text-overflow: ellipsis !important;
  padding: 0;
  margin: 0;
}

.search-dropdown-item:hover, .search-dropdown-item.focus {
  background-color: var(--main-transparent);
  color: var(--main-color);
}

.phantom-commentor {
  height: 44px;
  width: 100%;
}

.select-input-group {
  display: flex;
}
.select-input-group.small {
  flex-direction: column;
}

.select-input {
  width: 100%;
}

.select-input input {
  transition: none;
  width: 100%;
  /* border: 1px solid var(--gray-border); */
  color: var(--dark-gray);
  padding: 10px 15px !important;
  border-radius: 7px;
  background-color: #fff !important;
  text-overflow: ellipsis;
}

.select-input input:focus{
  border: none;
  outline: none;
}

.general-input-size {
  width: 100%;
}

.select-input.active input {
  border: 1px solid var(--main-color)
}

.select-input-container {
  display: flex;
  align-items: center;
  position: relative;
  transition: width 300ms ease-in-out;
}

.select-input-container.commentable {
  max-width: 246px;
}

.select-input-container.small {
  max-width: 100%;
}

.select-input-group.small .search-comment-container{
  margin-left: 0px;
  margin-top: 15px;
}

.select-input-icon{
  padding-left: 5px;
  color: var(--dark-gray);
  transition: all 200ms ease-in-out;
}

.select-input-icon.active, 
.text-input-title.active,
.select-input-icon:hover {
  color: var(--main-color);
}

.select-input-icon, 
.select-input-container input {
  cursor: pointer;
}

/* Textarea */
.text-input.textarea {
  min-height: 80px;
  resize: vertical;
  text-align: justify;
}

.inputComment-container {
  margin-top: 5px;
  transition: all 400ms ease-in-out;
}

.inputComment-container.no-dropped {
  /* display: none; */
  overflow: hidden;
  height: 0px !important;
}

.inputComment-container.dropped {
  /* display: none; */
  overflow: hidden;
  height: 100% !important;
}

.inputComment-label {
  margin: 5px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.inputComment-label label {
  color: var(--dark-gray)
}
/* Switch */
.switch-container {
  /* width: 100%; */
  min-width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  border: 1px solid var(--gray-border);
  color: var(--dark-gray);
  border-radius: 7px;
}

.add-switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
}

.switch-container.focused,
.add-switch-container.focused {
  border: 1px solid var(--main-color);
}

.switch-container .on {
  color: var(--main-color);
}

.dx-switch{
  width: 60px !important;
  height: auto !important;
}
.dx-switch-wrapper {
  position: static !important;
  box-sizing: border-box !important;
}

.dx-switch-on-value .dx-switch-container::before{
  background-color: var(--main-transparent) !important;
}

.dx-switch-on-value .dx-switch-handle::before{
  background-color: var(--main-color) !important;
}

.text-input-container .switch {
  padding-left: 0px !important;
}

.switch-comment-container {
  display: flex;
}

/* Table */
.table-container {
  margin-top: 40px
}
/* InputList */
.dx-list-item {
  cursor: default !important;
}
/* Select */
.text-input-container .dx-texteditor::after, .dx-texteditor.dx-state-focused::before,  .indigo-input .dx-texteditor::after {
  content: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .section-w-size-1 {
    width: calc(100%);
    min-width: 185px;
  }

  /* Scrollbar */

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--main-color); 
    border-radius: 5px;
  }

  .search-content {
    flex-direction: column;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .section-w-size-1 {
    width: calc(50%);
    min-width: 185px;
  }
  .section-w-size-2 {
    width: calc(100%);
    min-width: 220px;
  }
  .section-w-size-25 {
    width: 100%;
  }

  /* Scrollbar */

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--main-color); 
    border-radius: 5px;
  }

  .search-content {
    flex-direction: column;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .section-w-size-1 {
    width: calc(33.333%);
    min-width: 185px;
  }
  .section-w-size-2 {
    width: calc(66.666%);
    min-width: 270px;
  }
  .section-w-size-3 {
    width: calc(100%);
    min-width: 550px;
  }

  .section-w-size-25 {
    width: 100%;
  }

  /* Scrollbar */

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 0px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--main-color); 
    border-radius: 10px;
    
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1080px) {
  .section-w-size-1 {
    width: calc(25%);
    min-width: 185px;
  }
  .section-w-size-2 {
    width: calc(50%);
    min-width: 320px;
  }
  .section-w-size-3 {
    width: calc(75%);
    min-width: 500px;
  }
  .section-w-size-4 {
    width: calc(100%);
    min-width: 790px;
  }
  .section-w-size-25 {
    width: 25%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1285px) {
  .section-w-size-1 {
    width: calc(20%);
    min-width: 185px;
  }
  .section-w-size-2 {
    width: calc(40%);
    min-width: 370px;
  }
  .section-w-size-3 {
    width: calc(60%);
    min-width: 550px;
  }
  .section-w-size-4 {
    width: calc(80%);
    min-width: 740px;
  }
  .section-w-size-5 {
    width: calc(100%);
    min-width: 920px;

  }

}
.input-size-1-1 {width: 100%;}
.input-size-1-2 {width: 50%;}
.input-size-1-3 {width: calc(100% / 3);}
.input-size-1-4 {width: 25%;}
.input-size-1-5 {width: 20%;}

.input-size-2-1, 
.input-size-2-2 {width: 100%;}
.input-size-2-3 {width: calc(2 / 3 * 100%);}
.input-size-2-4 {width: 50%;}
.input-size-2-5 {width: calc(2 / 5 * 100%);}

.input-size-3-1, 
.input-size-3-2, 
.input-size-3-3  {width: 100%;}
.input-size-3-4 {width: calc(3 / 4 * 100%);}
.input-size-3-5 {width: calc(3 / 5 * 100%);}

.input-size-4-1, 
.input-size-4-2, 
.input-size-4-3, 
.input-size-4-4 {width: 100%;}
.input-size-4-5 {width: calc(4 / 5 * 100%);}

.input-size-5-1, 
.input-size-5-2, 
.input-size-5-3, 
.input-size-5-4, 
.input-size-5-5 {width: 100%;}


.input-size-25-5 {
  width: 25%;
} 


.small-screen {
  width: 100%;
}

/* Popup */

.cancel-popup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
}

.popup-content {
  width: 100%;
}

.popup-leftside {
  width: 60%;
  height: 100%;
  border-right: 1px solid var(--gray-border);
}

.popup-rightside {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 40%;
}
.popup-section {
  display: flex;
  flex-direction: column;
}

.popup-section-title {
  font-size: 1.2em;
  font-weight: 500;
  color: var(--dark-gray);
}

.popup-section-info {
  margin: 5px 0;
}

.popup-section-info:last-child {
  margin-bottom: 30px;
}

.popup-section-subtitle {
  font-size: 0.8em;
  color: var(--dark-gray);
}

.popup-section-paragraph {
  font-size: 1em;
  color: var(--dark-gray);
}

.popup-section-content{
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.size-btns .dx-button{
  margin: 5px;
}

.popup-title {
  font-size: 1.2em;
  font-weight: 500;
  color: var(--dark-gray);
  margin: 2px 0;
}

.popup-alert {
  padding: 20px 0;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  height: 2.2em;
  font-size: 1em;
  font-weight: 400;
  margin: 2px 1px;
}

.popup-alert, 
.popup-alert b, 
.popup-alert li{
  transition: all 500ms ease;
}

.popup-alert.on {
  color: var(--dark-gray);
}

.popup-alert.on b, 
.popup-alert.on li{
  color: var(--main-color) !important;
  padding: 0px 5px !important;
}

.popup-alert.off {
  color: transparent;
}

.popup-alert.off b, 
.popup-alert.off li{
  color: transparent !important;
}

.popup-alert li:first-child{
  font-size: 2em !important;
}


.popup-alert li{
  display: inline;
}


.popup-id-title {
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  z-index: 200;
}

.w-half {
  width: 50% !important;
}

.popup-group.id{ 
  width: 25%;
}

.popup-group.name{ 
  width: 75%;
}

.popup-group.value{
  width: 15%;
}

.popup-group.fullwidth {
  width: calc(100% - 20px);
}

.popup-group .dx-button, .unique-add-btn-container .dx-button {
  background-color: var(--main-color) !important;
  border-radius: 4px !important;
  color: #fff !important;
}

#gridContainer {
/*  margin-top: -50px;
  z-index: 10 !important;*/
}

.popup-group .dx-datagrid-header-panel, 
.popup-group .dx-datagrid, 
.popup-group .dx-toolbar{
  background-color: transparent !important;
}
.big-group {
  margin: 10px 0px !important;
}

.cancel-buttons {
  justify-content: center;
}

.cancel-buttons .dx-button{
  margin: 0 15px;
}

.dx-accordion-item{
  box-shadow: none !important;
}


.dx-toolbar-after .dx-button.dx-button-has-text { 
  color: #fff !important;
}

.dx-button-mode-outlined.dx-button-default, 
.dx-popup-bottom.dx-toolbar .dx-toolbar-item:last-child .dx-button-has-text,
.dx-button.dx-button-mode-outlined {
  background-color: transparent !important;
  color: var(--main-color) !important;
  border: 1px solid var(--main-color) !important;
}

.dx-button.dx-button-default,
.dx-checkbox-checked .dx-checkbox-icon, 
.dx-checkbox-indeterminate .dx-checkbox-icon, 
.dx-pager .dx-pages .dx-selection, 
.dx-pager .dx-page-sizes .dx-selection,
.dx-tab-selected::before,
.dx-scheduler-date-time-indicator,
.dx-calendar-selected-date span {
  background-color: var(--main-color) !important;
}

.popup-group .dx-link-icon, 
.dx-theme-material-typography a,
.dx-tab-selected .dx-tab-text,
.dx-scheduler-time-panel-current-time-cell div,
.dx-calendar-caption-button .dx-button-text,
.dx-scheduler-navigator-caption .dx-button-text,
.dx-scheduler-header-panel-current-time-cell .dx-scheduler-header-panel-cell-date {
  color: var(--main-color) !important;
}

.dx-button-mode-text.dx-button-default,
.dx-button-mode-contained .dx-button-text {
  color: #fff;
}

.dx-datagrid-rowsview .dx-row-removed > td,
.dx-scheduler-appointment.dx-state-hover {
  background-color: var(--main-transparent) !important;
  border: 1px solid var(--main-transparent) !important;
}


.popup-group{ 
  width: 100%;
  border: 1px solid var(--gray-border-light);
  border-radius: 8px;
  padding: 10px;
  margin: 10px 10px;
}

.show-form-item{
   height: 100%;
   padding: 20px;
   margin: 10px;
   /* border: 1px solid var(--gray-border-light); */
   border-radius: 8px;
   position: relative;
}

.show-form-item:hover{
  background-color: var(--hover-gray);
}

.p0{
  padding: 0px;
}

.show-form-item-comment{
  height: 100%;
  padding: 20px;
  margin: 10px;
  border: 1px solid var(--main-color);
  border-radius: 8px;
}

.popup-group.row{ 
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-list {
  height: 250px;
  overflow-x: none;
  overflow-y: auto;
}

.size-btns .dx-button-has-text {
  min-width: 36px !important;
}

.save-item-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 20px;
  color: var(--dark-gray);
}

/* Header */

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  margin-top: 10px;
}

p {
  color: var(--dark-gray);
}


h3 {
  margin: 0;
  padding: 0;
  font-size: 25px !important;
  font-weight: 700 !important;
  color: var(--main-color);
}

h4 {
  margin: 0;
  padding: 0;
  font-size: 20px !important;
  font-weight: 700 !important;
  color: var(--dark-gray);
}

.header-container h5 {
  color: var(--main-color);
  font-weight: 700 !important;
}
.header-container h5, 
.header-container p{
  margin: 0 !important;
}

.header-content {
  padding-left: 30px;
  color: var(--dark-gray);
}

.header-info,
.header-info span {
  display: flex;
}

.header-info p{
  margin-right: 10px !important;
  color: var(--dark-gray);
}

.header-content .loading{
  background-color: var(--dark-gray);
  /* border-radius: 3px; */
  opacity: 0.1;
  color: transparent;
  user-select: none;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  flex-direction: column;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.send-btn-container {
  height: 40vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.send-btn-title {
  margin: 1em 0;
  color: var(--dark-gray)
}
.send-btn {
  padding: 1em 2em;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  color: #fff;
  font-weight: 700;
  border-radius: 30px;
  transition: all 200ms ease;
  cursor: pointer;
}

.send-btn.small {
  padding: 0.5em 1em;
  border-radius: 4px;
}

.send-btn.incomplete {
  background-color: var(--light-gray);
  border: 1px solid var(--dark-gray);
  color: var(--dark-gray);
}

.send-btn.incomplete:hover {
  background-color: var(--dark-gray);
  border: 1px solid var(--dark-gray);
  color: var(--light-gray);
}

.send-btn:hover {
  background-color: var(--main-transparent);
  border: 1px solid var(--main-color);
  color: var(--main-color);
}

.send-section {
  border-top: 1px solid var(--main-transparent);
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 50px;
  position: absolute;
}

#agendaEspecialities .dx-header-row {
  display: none;
}

#agendaEspecSelect.mgmt-dropdown-container.droped {
 box-shadow: var(--shadow);
}

#agendaEspecSelect.mgmt-dropdown-container.droped,
#agendaEspecSelect input {
  border: 1px solid transparent;
}

#agendaEspecSelect .mgmt-dropdown-content.droped,
#agendaEspecSelect .mgmt-dropdown-group-nopadding {
  padding: 0 0 !important;
}

.dx-scrollable-native.dx-scrollable-vertical, 
.dx-scrollable-native.dx-scrollable-vertical > .dx-scrollable-wrapper > .dx-scrollable-container, 
.dx-scrollable-native.dx-scrollable-vertical > div > .dx-scrollable-wrapper > .dx-scrollable-container 
{
  overflow-y: hidden !important;
}

.dx-button-success .dx-button-content {
 background-color: #8bc34a !important;
}

.dx-lookup {
  width: 100% !important;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--main-color); 
}

footer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0 30px 10px 30px;
  bottom: 10px;
  /* right: 10px; */
}

footer .powered-by {
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--dark-gray);
  margin-bottom: 10px;
}

footer .powered-by span {
  margin-right: 15px;
  margin-bottom: 5px;
  color: var(--dark-gray);
  font-weight: 700;
  font-size: 0.8em;
}

footer img {
  margin: 0;
  padding: 0;
  justify-self: center;
  filter: grayscale(1);
  opacity: 0.3;
  transition: all 200ms ease-in-out;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

footer img.ips {
  width: 80px;
  height: 80px;
}

footer img.indigo {
  height: 28px;
}

footer img.no-loaded {
  background-color: rgba(133, 133, 133, 0.5);
  border-radius: 5px;
  opacity: 0.2;
}

footer .indigo-loading {
  height: 30px;
  width: 105px;
  background-color: var(--dark-gray);
  opacity: 0.6;
  border-radius: 4px;
}

footer img:hover {
  opacity: 1;
  filter: none;
}