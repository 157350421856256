:root {
    --parametrizer-height: 150px;
    --new-item-green: rgba(131, 255, 137, 0.356);
}

.main-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 76px);
    /* flex-grow: 1; */
}

.main-container.small {
    height: calc(100vh - 86px);
    /* flex-grow: 1; */
}

.ficha-parametrizer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sections-container {

    display: flex;
    margin-bottom: 40px;
}

.sections-content {
    height: var(--parametrizer-height);
    border: 1px solid var(--gray-border-light);
    margin: 0px 20px;
    position: relative;
    transition: all 400ms ease-in;
}

.section-list{
    position: relative;
    height: calc(var(--parametrizer-height) - 30px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 30px;
}

.section-btn {
    width: 100%;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 0;
    padding: 2px 0;
}
.section-btn p{
    margin: 2px;
    /* padding: 20px; */
}
.section-btn span {
    /* margin-right: 10px; */
    position: absolute;
    right: 10px;
}
.dx-icon {
    color: var(--main-color) !important;
}
.section-btn:hover, .sb-active {
    background-color: var(--main-transparent);
}
.sb-inactive {
    color: rgb(202, 202, 202);
    cursor: default;
}
.sb-inactive:hover {
    background-color: rgba(214, 213, 213, 0.24);
}
.section-btn p{
    padding: 6px 10px;
}

.section-closed-text {
    position: absolute;
    left: 0px;
    width: 30px;
    height: var(--parametrizer-height);
    text-align: center;
    writing-mode: tb-rl;
    display: flex;
    justify-content: center;
    align-items: center;
}

.closed-text{
    font-weight: 700;
    color: var(--main-color);
    transform: rotate(180deg);
}

.section-opened-text {
    position: absolute;
    right: 0;
    bottom: -30px;
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.section-searcher {
    position: absolute;
    right: 0;
    top: 0px;
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-bottom: 1px solid var(--main-transparent);
}

.section-searcher input {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    border: transparent;
    color: var(--main-color);
}

.section-searcher input:focus {
    outline: transparent;
    border: transparent;
}

.opened-text  {
    font-weight: 700;
    color: var(--main-color);
}

.closed {
    width: 30px;
    border: 1px solid transparent;
}

.opened {
    width: 20%;
}

.section-toggler {
    position: absolute;
    right: -40px;
    bottom: 0px;
    height: var(--parametrizer-height);
    width: 40px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.section-toggler:hover {
    background-color: var(--main-transparent);
}

/* Popup */

.cancel-popup {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cancel-title {
    padding: 15px 0;
    margin-bottom: 15px;
    text-align: center;
    color: var(--dark-gray);
}

.cancel-title b{
    color: var(--main-color);
}

.dx-toolbar-item-content{
    color: var(--dark-gray);
}

.cancel-buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.cancel-popup i {
    color: #fff !important;
}

/* Menu Panel */
.menu-panel-container {
    position: relative;
}

.menu-panel {
    height: var(--parametrizer-height);
    width: 70px;
    margin-left: 20px;
    overflow: auto;
    justify-content: center;
    align-items: space-around;
}

.menu-panel-item {
    width: 48px;
    height: 48px;
    border: 1px solid var(--main-color);
    border-radius: 5px;
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms ease-in-out;
}

.menu-panel-item:hover, .menu-panel-item.active {
    background-color: var(--main-color);
    border: 1px solid var(--gray-border-light);
}

.menu-panel-item:hover .dx-icon, .menu-panel-item.active .dx-icon{
    color: #fff !important;
}

.menu-panel-item .dx-icon{
    z-index: 100;
}
.menu-panel-item .dx-button-mode-text.dx-state-focused,
.menu-panel-item .dx-button-mode-text.dx-state-hover {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/* New option  */

.new-item {
    background-color: var(--new-item-green) !important;
    color:rgba(2, 31, 3, 0.918) !important;
}


.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused) {
    background-color: var(--main-transparent) !important;
    color: var(--secondary-color) !important;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link {
    background-color:  transparent !important;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td {
    border-bottom: var(--secondary-color) !important;
}

.dx-scrollable-scroll-content {
    background-color: var(--main-color) !important; 
    border-radius: 2px;
}