.side-nav-inner-toolbar {
  width: 100%;
}

.container {
  height: 100vh;
  flex-direction: column;
  display: flex;
}

.layout-body {
  flex: 1;
  min-height: 0;
}

.content {
  flex-grow: 1;
}

#navigation-header {
  @import "../../themes/generated/variables.additional.scss";
  z-index: 100;

  .menu-button .dx-icon {
    color: var(--main-color);
  }

  .screen-x-small & {
    padding-left: 20px;
  }
    
  .dx-theme-generic & {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
