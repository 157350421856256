

.rjv-demo .rjv-settings,
.rjv-demo.rjv-source {
    vertical-align: top;
    display: inline-block;
    margin-top: 5px;
    margin-right: 10px;
    min-width: 300px;
    max-width: 400px;
}

.rjv-input {
    margin-bottom: 5px;
}

.rjv-input .rjv-label {
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
}