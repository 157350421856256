.agenda-tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    text-align: center;
    /* padding: 15px; */
}

.agenda-tooltip-info {
    width: 100%;
}

.agenda-tooltip-data {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.agenda-tooltip-data p{
    margin: 0px;
}

.agenda-tooltip-data .title{
    margin-top: 5px;
    font-weight: 700;
    color: var(--secondary-color);
    border-radius: 5px;
}

.agenda-tooltip-combo {
    margin-top: 30px;
    width: 100%;
}

.agenda-info {
    margin-bottom: 10px;
}

.dx-calendar-cell.dx-calendar-today {
    color: var(--main-color) !important;
}

.dx-calendar-cell.dx-calendar-selected-date span {
    background-color: var(--main-color) !important;
}

.agenda-btn-container {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid rgba(194, 194, 194, 0.397);
    display: flex;
    justify-content: space-around;
}

.agenda-btn { 
    border-radius: 4px;
    margin-top: 10px;
    padding: 10px 8px; 
    transition: all 100ms ease-in-out;
    cursor: pointer;
}

.agenda-btn:hover, 
.dx-data-row:hover,
.dx-selection { 
    background-color: var(--main-transparent);
    /* color: var(--main-color) !important; */
}
.dx-data-row {
    cursor: pointer;
}

/* Appointment */
@media only screen and (max-width: 768px) {
    .dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper .dx-overlay-content {
        width: 79% !important;
        left: 0% !important;
    }

    .dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper .dx-popup-content .dx-list-item {
        width: 100% !important;
    }

    .dx-popup-content {
        width:  100% !important;
    }

    .agenda-btn { 
        border-radius: 4px;
        margin-top: 10px;
        padding: 5px 4px; 
        transition: all 100ms ease-in-out;
    }

    .agenda-tooltip {
        padding: 5px;
        width: 95%;
    }
}

.scheduler .dx-scheduler-appointment {
    background-color: var(--main-transparent);
    color: var(--secondary-color); 
}

.scheduler .dx-scheduler-navigator .dx-button {
    color: var(--main-color) !important;
}

.scheduler .dx-tab.dx-tab-selected {
    color: var(--main-color);
}

.scheduler .dx-tab.dx-tab-selected::before {
    background-color: var(--main-color);
}

.scheduler .dx-scheduler-navigator .dx-button.dx-state-hover, 
.scheduler .dx-scheduler-navigator .dx-button.dx-state-active,
.scheduler .dx-scheduler-navigator .dx-button.dx-state-focused {
    background-color: var(--main-transparent);
}

.appointment-name {
    font-size: 12px;
    /* color: #fff !important; */
    text-shadow: 1px 1px 0 rgba(207, 207, 207, 0.205);
}
.appointment-info {
    font-size: 10px;
    /* color: #fff !important; */
    text-shadow: 1px 1px 0 rgba(207, 207, 207, 0.205);
}

.legend-filter {
    padding: 0 5px;
    margin: 0 5px;
    border-radius: 7px;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.legend-filter.active,
.legend-filter:hover {
    /* border: 1px solid red; */
    box-shadow: var(--shadow) inset;
}