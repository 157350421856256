.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}

.historial-btn-container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  .dx-button {
    margin: 0 10px;
  }
}

.p5{
  padding: 5px;
}