
.card-container {
    /* background-color: red; */
    height: 80px;
    padding: 8px;
    border-radius: 10px;
    width: 200px;
    /* min-width: 150px;
    max-width: 20%; */
    margin: 20px;
    -webkit-box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.10); 
    box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.10);
}
.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.card-title {
    color: var(--main-color);
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 10px;
}
.card-text {
    color: var(--dark-gray);
    font-size: 1.0em;
}


.dx-toolbar .dx-toolbar-items-container {
    height: 66px !important;
    overflow: visible;
}

.dx-multiview-item-content {
    width: 100%;
    height: 96% !important;
}