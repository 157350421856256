:root{
    --main-color: #43b3f3;
    --main-transparent: #62bff53d;
    --secondary-color: #245f90;
    --secondary-transparent: #4b88bb3d;
    /* --main-color: #f3435d;
    --main-transparent: #fdd1d888; */
    /* --secondary-color: #000159;
    --secondary-transparent: #00015983; */
}

/* Stylizers */
.top-frame {
    width: 100%;
    /* height: 20px; */
    background-color: var(--main-color);
}


/* Main Sections */

/* Home */
.home-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-content-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-header-container {
    display: flex;
    height: 35%;
    width: 90%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.home-options-container {
    display: flex;
    height: 45%;
    justify-content: space-around;
    align-items: center;
}

.home-options-container a{
    text-decoration: none;
}

.home-option {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-in-out;
    cursor: pointer;
}

.home-option-title {
    text-align: center;
    width: 75%;
    font-weight: 700;
    font-size: 1.2em;
    color: var(--secondary-color);
    transition: all 200ms ease-in-out;
    margin-top: 10px; 
}

.home-option svg g path {
    transition: all 200ms ease-in-out;
}

.home-option:hover {
    background-color: var(--main-transparent);
}

.home-option:hover .home-option-title{
    color: var(--main-color);
}

.home-option:hover svg g path{
    fill: var(--main-color);
}

.home-container .top-frame {
    position: absolute;
    top: 0;
}

.home-container h1 {
    text-align: center;
    margin: 0;
    color: var(--main-color);
}

.hero-message {
    font-size: 1.8em;
    color: var(--secondary-color);
}



.main-content-container {
    display: flex;
    /* min-height: 100%; */
    height: calc(100vh - 96px);
    flex-direction: column;
    justify-content: space-between !important;
}



.main-field-container {
    display: flex;
    flex-direction: column;
    /* flex-grow: 1; */
    align-items: center;
    justify-content: center;
}

.main-field-container.hc {
    justify-content: flex-start;
}

.field-label {
    margin: 5px 0px;
    font-size: 1.3em;
    font-weight: 500;
    color: var(--secondary-color);
}

.field-label.center {
    text-align: center;
}

.field-container {
    width: 70%;
    flex-direction: column;
    align-items: center;
}

  
.content {
    /*height: 100%;
     overflow: hidden; */
}

.content.agenda {
    height: calc(100vh - 96px);
    overflow: auto;
}

.no-margin {
    margin: 0;
}

/* Schedule */
.scheduler-container {
    width: 100%;
    display: flex;
    justify-content: center;
}


.main-content-container .text-input-title{
    text-align: center;
}

.main-content-container .dx-theme-material-typography h1 {
    font-weight: 700 !important;
}

.main-content-container .btn-container {
    margin-bottom: 10vh;
    display: flex;
    justify-content: center;
}

.form-card {
    padding: 20px 50px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.226);
}

.header-logo {
    margin-left: 30px;
}

.dx-swatch-additional {
    background-color: #fff !important;
}

.dx-toolbar-menu-last-section {
    display: none !important;
}

.dx-toolbar-before .dx-item-content {
    display: flex !important;
}

/* Responsive */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    :root { 
        font-size: 8px;
    }

    /* Home */
    .home-content-container {
        height: 85%;
    }
    .home-options-container {
        width: 90%;
    }
    .home-option {
        font-size: 12px;
        height: 130px;
        width: 130px;
    }

    .page-title {
        width: 100%;
        text-align: center;
        font-weight: 700 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        font-size: 45px !important;
        padding: 0 10%;
        color: var(--secondary-color);
    }

    .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
        width: 100%;
    }

    .main-content-container .select-input-container{
        max-width: 70% !important;
        left: 15%
    }

    /* Left side menu */
    .menu-icon {
        height: 65px;
    }

    .scheduler {
        margin: auto;
        width: 85%;
    }
    

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    :root { 
        font-size: 8px;
    }

    /* Home */
    .home-content-container {
        height: 95%;
    }
    .home-options-container {
        width: 90%;
    }
    .home-option {
        height: 150px;
        width: 150px;
    }

    .page-title {
        width: 100%;
        text-align: center;
        font-weight: 700 !important;
        margin-bottom: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        font-size: 60px !important;
        color: var(--secondary-color);
    }

    .main-content-container .select-input-container{
        max-width: 70% !important;
        left: 15%
    }

    .menu-icon {
        height: 55px;
    }

    .menu-button-container {
        transform: translateY(-15vh) !important;
    }

    .scheduler {
        margin: auto;
        width: 85%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    /* Home */
    .home-content-container {
        height: 95%;
    }
    .home-options-container {
        width: 70%;
    }
    .home-option {
        height: 200px;
        width: 200px;
    }

    .page-title {
        font-size: 60px !important;
    }

    .menu-icon {
        height: 65px;
    }

    .menu-button-container {
        transform: translateY(-76px) !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    /* Home */
    :root { 
        font-size: 15px;
    }

    .home-options-container {
        width: 50%;
    }
    
    .page-title {
        font-size: 70px !important;
    }

    .main-content-container .select-input-container{
        max-width: 50% !important;
        left: 25%
    }

    .scheduler {
        margin: auto;
        width: 80%;
    }
    
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
/* Helpers */
    /* Size */

    .w-100 {
        width: 100%;
    }

    /* Typography */
    h1{
        margin: 0 0 30px 40px;
    }

    .page-title {
        font-size: 86px !important;
    }

    .roboto {
        font-family: Roboto,RobotoFallback,"Noto Kufi Arabic",Helvetica,Arial,sans-serif;
    }
    
}