.dx-toolbar {
    padding: 5px 10px !important;
}
.panel-list2 .dx-list-item{
    /* background-color: red; */
    cursor: pointer !important;
}
.dx-list-item-icon-container, .dx-toolbar-before {
    width: 36px !important;
    padding-right: 0px !important ;
    text-align: center !important;
}
 
.dx-list-item-content {
    padding-left: 10px !important;
}

.dx-button {
    /* background-color: rgba(191, 191, 191, -0.15) !important; */
    border: none !important;
}

.panel-list {
    height: 400px !important;
}

.dx-drawer-expand.dx-drawer-right .panel-list {
    float: right !important;
}

.panel-list .dx-list-item {
    color: #fff !important;
    border-top: 1px solid rgba(221, 221, 221, .2) !important;
}

.panel-list .dx-list-item .dx-icon {
    color: #fff !important;
}

.options {
    padding: 20px !important;
    background-color: rgba(191, 191, 191, .15) !important;
}

.caption {
    font-size: 18px !important;
    font-weight: 500 !important;
}

.option {
    margin-top: 10px !important;
    display: inline-block !important;
    margin-right: 50px !important;
}

label {
    font-weight: bold !important;
}

#content {
    height: 100% !important;
    padding: 10px 20px !important;
}

#content h2 {
    font-size: 26px !important;
}

.dx-toolbar-after .dx-button.dx-button-has-text.exit-button {
    color: var(--main-color) !important;
}