.mgmt-dropdown-warning span {
    cursor: pointer;
}

.mgmt-input:disabled {
    border: 1px solid rgba(180, 180, 180, 0.3);
    color: var(--gray-border-light) !important;
}

.mgmt-input:read-only {
    cursor: default;
}

.mgmt-input-container {
    position: relative;
}

.mgmt-dropdown-container {
    width: calc(100% - 20px);
    position: absolute;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: transparent;
    /* height: 0px; */
    transition: all 300ms ease-in-out;
    z-index: 1500;
}

.mgmt-dropdown-container .dx-texteditor-input {
    font-size: 1em !important;
    padding-left: 40px !important;
}

.mgmt-dropdown-container.droped {
    border: 1px solid var(--gray-border);
    background-color: #fff;
}

.mgmt-dropdown-content {
    display: none;
}

.mgmt-dropdown-content.droped {
    height: 100%;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.mgmt-input::-webkit-outer-spin-button,
.mgmt-input::-webkit-inner-spin-button {
  -webkit-appearance:inherit !important;
  margin: 0;
}

.mgmt-dropdown-group{
    border-bottom: 1px solid var(--gray-border-light);
}

.mgmt-dropdown-group input{
    width: 100%;
}

.mgmt-dropdown-group-horizontal {
    padding: 10px 20px;
    width: 100%;
}

.mgmt-dropdown-group-nopadding {
    padding: 0px 10px;
    width: 100%;
}

.mgmt-dropdown-group-horizontal.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
}


.mgmt-drop-input.disabled{
    border: 1px solid rgba(118, 118, 118, 0.3);
    outline: none;
}

.mgmt-dropdown-label.disabled,
.mgmt-group-label.disabled {
    color: var(--gray-border-light) !important;
}
.mgmt-dropdown-label {
    color: var(--dark-gray);
    font-weight: bold;
    font-size: 1.2em;
    padding-bottom: 5px;
}

.mgmt-cancel-btn,
.mgmt-dropdown-warning span {
    cursor: pointer !important;
    color: var(--dark-gray);
}

.mgmt-cancel-btn:hover, 
.mgmt-dropdown-warning span:hover {
    color: var(--main-color);
    font-weight: 500;
}

.mgmt-dropdown-warning {
    color: var(--main-color);
    font-size: 0.8em;
}
.mgmt-input textarea {
    resize: none;
    height: 110px;
    width: 100%;
}

.warning-disabled {
    color: var(--gray-border-light) !important;
    font-size: normal !important;
    cursor: default !important;
}

.mgmt-content-text {
    color: var(--dark-gray);
    padding: 10px;
    transition: all 400ms ease-in-out;
}


.switch-text {
    color: var(--dark-gray);
}

.switch-text.on {
    color: var(--main-color);
}

.switch-text.disabled {
    color: var(--gray-border-light) !important;
}

.dx-datagrid-borders > .dx-datagrid-headers, .dx-datagrid-borders > .dx-datagrid-rowsview, .dx-datagrid-borders > .dx-datagrid-total-footer {
    border: none !important;
}
/* Doses */